import React, { Fragment, useState, useEffect, Suspense } from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import BigPageHeading from "./BigPageHeading";
import PageHeading from "./PageHeading";
import { motion } from "framer-motion";
import TagsContainer from "./TagsContainer";

import RelatedStoryContainer from "./RelatedStoryContainer";
import Loading from "./Loading";

const useStyles = createUseStyles((theme) => ({
  leftContainer: {
    // display: "flex",
    // width: "70%",
    maxWidth: "70%",
    "@media(max-width:750px)": {
      maxWidth: "100%",
    },
  },

  body: {
    fontFamily: "Libre Baskerville",
    fontSize: "1.75rem",
    color: theme.colorText,
    marginBottom: ".5em",
    whiteSpace: "pre-wrap",
    lineHeight: "1.1em",
    textAlign: "left",
  },
  date: {
    color: theme.colorPrimaryOpacity50,
    fontFamily: "Libre Baskerville Italic",
    fontSize: "1.25rem",
    marginBottom: ".75em",
    marginTop: ".75em",
  },
  relatedStoryTitle: {
    fontFamily: "Libre Baskerville Italic",
    fontSize: "1.25rem",
    marginTop: ".75em",
    color: theme.colorPrimaryOpacity50,
  },
}));

function SingleStory(props) {
  const classes = useStyles();
  const date = new Date(props.story.publishedAt).toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    year: "numeric",
    day: "numeric",
  });

  return (
    <div className={classes.leftContainer}>
      <Suspense fallback={<Loading />}>
        {props.story && (
          <Fragment>
            <BigPageHeading heading={props.story.title} />
            <motion.h3
              className={classes.date}
              initial={{ opacity: 0, translateX: -100 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              transition={{ duration: 0.5, delay: 0.25 }}
              viewport={{ once: true }}
            >
              Daily Story - {props.story.publishedAt ? date : ""}
            </motion.h3>
            <motion.p
              className={classes.body}
              initial={{ opacity: 0, translateX: -100 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              {props.story.body}
            </motion.p>
            <motion.h3
              className={classes.date}
              initial={{ opacity: 0, translateY: -100 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              {props.story.publishedAt ? date : ""}
            </motion.h3>
            {/* <TagsContainer /> */}
            {props.story && (
              <PageHeading
                heading="If you liked this story, check out some of our other stories!"
                subHeading="See what other readers who enjoyed the same story recommend for further reading. This curated list of suggestions will lead you to discover new stories that share similar qualities and have resonated with other fans."
                width100
                link="past-stories"
                buttonText="Past Stories"
                callToAction
              />
            )}
            <motion.p
              className={classes.relatedStoryTitle}
              initial={{ opacity: 0, translateY: -100 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              More stories like this one
            </motion.p>
            <RelatedStoryContainer />
          </Fragment>
        )}
      </Suspense>
    </div>
  );
}

export default SingleStory;
