import React from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    height: "100%",
    top: "0",
    left: "0",
    width: "100%",
    zIndex: "100000",
    background: "rgba(0, 0, 0, 0.9)",
    transition: "all .5s ease-in-out",
    // padding: "1em",
  },
  menuItem: {
    display: "flex",
    fontFamily: "Bebas",
    fontSize: "4rem",
    color: theme.colorTertiary,
    cursor: "pointer",
    borderBottom: "4px solid transparent",
    transition: "border .25s ease-in-out",
    "&:hover": {
      borderBottom: `4px solid ${theme.colorSecondary}`,
    },
  },
  menu: {
    display: "flex",
    flexDirection: "column",
    gap: "1em",
  },
}));

function NavModal(props) {
  const classes = useStyles();
  return (
    <motion.div
      className={classes.mainContainer}
      onClick={props.handleClose}
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 100 }}
    >
      <div className={classes.menu}>
        <motion.p
          className={classes.menuItem}
          initial={{ opacity: 0, translateX: -100 }}
          animate={{ opacity: 100, translateX: 0 }}
          transition={{ delay: 0.1 }}
        >
          <Link to="/past-stories">Past Stories</Link>
        </motion.p>
        <div className={classes.separator} />
        <motion.p
          className={classes.menuItem}
          initial={{ opacity: 0, translateX: -100 }}
          animate={{ opacity: 100, translateX: 0 }}
          transition={{ delay: 0.2 }}
        >
          <Link to="/random-story">Random Story</Link>
        </motion.p>
        <div className={classes.separator} />
        <motion.p
          className={classes.menuItem}
          initial={{ opacity: 0, translateX: -100 }}
          animate={{ opacity: 100, translateX: 0 }}
          transition={{ delay: 0.3 }}
        >
          <Link to="/about">About Us</Link>
        </motion.p>
        <div className={classes.separator} />
        <motion.p
          className={classes.menuItem}
          initial={{ opacity: 0, translateX: -100 }}
          animate={{ opacity: 100, translateX: 0 }}
          transition={{ delay: 0.4 }}
        >
          <Link to="/app">Get the App</Link>
        </motion.p>
      </div>
    </motion.div>
  );
}

export default NavModal;
