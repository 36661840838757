import React from "react";
import { createUseStyles } from "react-jss";
import Tag from "./Tag";
import { motion } from "framer-motion";

const useStyles = createUseStyles({
  mainContainer: {
    display: "flex",
    gap: "1em",
    flexWrap: "wrap",
    marginBottom: "1em",
    // padding: "1em"
  },
});

function TagsContainer() {
  const classes = useStyles();
  return (
    <motion.div
      className={classes.mainContainer}
      initial={{ opacity: 0, translateY: -100 }}
      whileInView={{ opacity: 1, translateY: 0 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
    >
      <Tag title="romance" />
      <Tag title="mystery" />
      <Tag title="true crime" />
      <Tag title="horror" />
    </motion.div>
  );
}

export default TagsContainer;
