import React from "react";
import { createUseStyles } from "react-jss";
import PageHeading from "./PageHeading";
import BigPageHeading from "./BigPageHeading";
import tigerPic from "../pics/tiger.svg";
import tigerHeadPic from "../pics/tigerhead.svg";
import sparrowPic from "../pics/sparrow.svg";
import RelatedStoryContainer from "./RelatedStoryContainer";

const useStyles = createUseStyles({
  mainContainer: {
    display: "flex",
    maxWidth: "1750px",
    // backgroundColor: "red",
    width: "100%",
    flexDirection: "column",
  },
  section: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "4em 0em",
  },
});

function AboutUsPage() {
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      <BigPageHeading heading="Whispers of Adventure: Portals to Other Worlds" />
      <section className={classes.section}>
        <PageHeading
          heading="Welcome to the Enigmatic World of Stories"
          subHeading="Embark on a journey into the unknown with us at the Enigmatic World of Stories. We believe that tales have the power to whisk us away to uncharted realms, awaken intense emotions, and forge connections with others. Our purpose is to unite a diverse community of readers and writers in a space where everyone can delve into the mysterious, explore, and share their enthusiasm for literature."
          reverse
          image={tigerHeadPic}
          maxHeight="700px"
          height="700"
          centered
        />
      </section>
      <section className={classes.secion}>
        <PageHeading
          heading="Daily Short Stories"
          subHeading="Delight in our daily offering of short stories, handpicked to ignite your imagination and feed your literary cravings. From popular genres like mystery, romance, science fiction, and fantasy to more obscure categories, our collection caters to every preference."
          maxHeight="700px"
          height="700"
          image={tigerPic}
          centered
        />
      </section>
      <section className={classes.secion}>
        <PageHeading
          heading="We Invite you to Join  Our Community"
          subHeading="We invite you to become part of our growing community of readers and writers, and experience the allure of storytelling with the Enigmatic World of Stories. Sign up for a free account today and begin your journey into the captivating realm of mystery and wonder."
          maxHeight="700px"
          height="700"
          image={sparrowPic}
          reverse
          centered
          callToAction
          link="past-stories"
          buttonText="View our Stories"
        />
      </section>
      <RelatedStoryContainer />
    </div>
  );
}

export default AboutUsPage;
