import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    position: "relative",
    background: theme.colorPrimary,
    color: theme.colorTertiary,
    padding: "1em 2em",
    borderRadius: "1em",
    cursor: "pointer",
    fontFamily: "Libre Baskerville Bold",
    fontStyle: "italic",
    transition: "all .25s ease-in-out",
    "&:hover": {
      backgroundColor: theme.colorPrimaryOpacity80,
    },
  },
}));

function Button(props) {
  const classes = useStyles();
  return (
    <div>
      <button style={{}} className={classes.mainContainer}>
        {props.text}
      </button>
    </div>
  );
}

export default Button;
