import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    border: `2px solid ${theme.colorPrimary}`,
    padding: ".25em 1em",
  },
  title: {
    color: theme.colorText,
    fontFamily: "Libre Baskerville",
    fontStyle: "italic",
  }
}));

function Tag(props) {
  const classes = useStyles()
  return(
    <div className={classes.mainContainer}>
      <p className={classes.title}>{props.title}</p>
    </div>

  );
}

export default Tag;