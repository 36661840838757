import React from "react";
import { createUseStyles } from "react-jss";
import RelatedStory from "./RelatedStory";
import { motion } from "framer-motion";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    gap: "1em",
    overflowX: "scroll",
    padding: "1em 0em",
    "&::-webkit-scrollbar": {
      backgroundColor: theme.colorPrimaryOpacity50,
      height: "1rem",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.colorPrimary,
    },
  },
}));

function RelatedStoryContainer() {
  const classes = useStyles();
  const [stories, setStories] = React.useState([]);
  React.useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/api/stories/random10-stories");
      const data = await response.json();
      setStories(data);
    };
    fetchData();
  }, []);
  return (
    <motion.div
      className={classes.mainContainer}
      initial={{ opacity: 0, translateY: -100 }}
      whileInView={{ opacity: 1, translateY: 0 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
    >
      {stories.map((story) => {
        return <RelatedStory story={story} />;
      })}
    </motion.div>
  );
}

export default RelatedStoryContainer;
