import React, { Fragment } from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import logo from "../pics/books.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import NavModal from "./NavModal";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    color: theme.colorText,
    width: "100%",
    // backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
    padding: "0em 1em",
  },
  menuItem: {
    display: "flex",
    // marginLeft: "1em",
    cursor: "pointer",
    fontSize: "1.75rem",
    padding: "0em .25em",
    fontFamily: "Libre Baskerville Italic",
  },
  rightContainer: {
    display: "flex",
    fontFamily: "Oswald Regular",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    gap: "1em",
    // margin: "2em",
    "@media (max-width: 750px)": {
      display: "none",
    },
  },
  leftContainer: {
    display: "flex",
    // flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // marginLeft: "1em",
    // marginTop: "1em",
    cursor: "pointer",
    // gap: "1em",
  },
  logo: {
    margin: "1em 0em",
    height: "100px",
    "@media(max-width:750px)": {
      height: "50px",
    },
  },
  title: {
    fontFamily: "Libre Baskerville",
    color: theme.colorPrimary,
    textTransform: "uppercase",
    fontSize: "2rem",
    marginLeft: ".5em",
    fontStyle: "italic ",
    "@media(max-width:750px)": {
      fontSize: "1.25rem",
    },
  },
  innerContainer: {
    display: "flex",
    maxWidth: "1750px",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  separator: {
    width: "2px",
    height: "25px",
    background: theme.colorPrimary,
  },
  linkContainer: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    "&:hover $underline": {
      width: "100%",
    },
  },
  underline: {
    height: "4px",
    width: "0%",
    backgroundColor: theme.colorPrimaryOpacity50,
    transition: "all .5s ease-in-out",
  },
  hamburgerMenu: {
    cursor: "pointer",
    fontSize: "2rem",
    color: theme.colorPrimary,
    transition: "all .25s ease-in-out",
    "@media (min-width: 750px)": {
      display: "none",
    },
    "&:active": {
      color: theme.colorPrimary,
    },
    "&:hover": {
      color: theme.colorPrimary,
    },
  },
}));

function CustomHeader() {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleClose = () => {
    setMenuOpen(false);
  };

  React.useEffect(() => {
  }, [menuOpen]);

  return (
    <Fragment>
      {menuOpen ? <NavModal handleClose={handleClose} /> : ""}
      <div className={classes.mainContainer}>
        <div className={classes.innerContainer}>
          <Link to="/">
            <div className={classes.leftContainer}>
              <img className={classes.logo} src={logo} />
              <h2 className={classes.title}>Whispers of Adventure</h2>
            </div>
          </Link>
          <div className={classes.rightContainer}>
            <Link className={classes.linkContainer} to="/random-story">
              <p className={classes.menuItem}>Random Story</p>
              <div className={classes.underline} />
            </Link>
            <div className={classes.separator} />
            <Link className={classes.linkContainer} to="/past-stories">
              <p className={classes.menuItem}>Past Stories</p>
              <div className={classes.underline} />
            </Link>
            <div className={classes.separator} />
            <Link className={classes.linkContainer} to="/app">
              <p className={classes.menuItem}>Get the App</p>
              <div className={classes.underline} />
            </Link>
          </div>
          <FontAwesomeIcon
            className={classes.hamburgerMenu}
            icon={faBars}
            onClick={() => {
              setMenuOpen(true);
            }}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default CustomHeader;
