import React from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import RightSidebar from "./RightSidebar";
import loadingPic from "../pics/loading.svg";
import SingleStory from "./SingleStory";

const useStyles = createUseStyles({
  mainContainer: {
    display: "flex",
    flexDirection: 'column',
    margin: "auto",
    marginTop: "2em",
    width: "100%",
    minHeight: "100vh",
    maxWidth: "1750px",
  },
  innerContainer: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    "@media(max-width:750px)": {
      flexDirection: "column",
    },
  },
  loading: {
    width: "100%",
    height: "200px",
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    minWidth: "70%",
  },
});

function RandomStoryPage() {
  const classes = useStyles();
  const [story, setStory] = React.useState([]);
  React.useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/api/stories/random-story");
      const data = await response.json();
      setStory(data[0]);
    };
    fetchData();
  }, []);
  return (
    <div className={classes.mainContainer}>
      <div className={classes.innerContainer}>
        {story ? (
          <SingleStory story={story} />
        ) : (
            <img className={classes.loading} src={loadingPic} />
        )}
        <RightSidebar />
      </div>
    </div>
  );
}

export default RandomStoryPage;
