import logo from "./logo.svg";
import "./App.css";
import HomePage from "./Components/HomePage";
import CustomHeader from "./Components/CustomHeader";
import CustomFooter from "./Components/CustomFooter";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import AboutUsPage from "./Components/AboutUsPage";
import ContactPage from "./Components/ContactPage";
import PastStoriesPage from "./Components/PastStoriesPage";
import NotFoundPage from "./Components/NotFoundPage";
import RandomStoryPage from "./Components/RandomStoryPage";
import { createUseStyles, ThemeProvider } from "react-jss";
import PrivacyPolicyPage from "./Components/PrivacyPolicyPage";
import TermsAndConditionsPage from "./Components/TermsAndConditionsPage";
import SingleStoryPage from "./Components/SingleStoryPage";
import ScrollToTop from "./Components/ScrollToTop";
import AppPage from "./Components/AppPage";
import BackgroundMountains from "./Components/BackgroundMountains";

const useStyles = createUseStyles({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: "0em 1em",
    // transition: "all 10s ease-in-out"
  }
})

const theme = {
  colorPrimary: "#6F1D1B",
  colorPrimaryOpacity25: "rgba(111, 29, 27, 0.25)",
  colorPrimaryOpacity50: "rgba(111, 29, 27, 0.50)",
  colorPrimaryOpacity80: "rgba(111, 29, 27, 0.80)",
  colorSecondary: "#51484F",
  colorSecondaryOpacity25: "rgba(81, 72, 79, 0.25)",
  colorSecondaryOpacity50: "rgba(81, 72, 79, 0.5)",
  colorSecondaryOpacity80: "rgba(81, 72, 79, 0.8)",
  colorTertiary: "#FFB6C1",
  colorTertiaryOpacity25: "rgba(255, 182, 193, 0.25)",
  colorTertiaryOpacity50: "rgba(255, 182, 193, 0.5)",
  colorTertiaryOpacity80: "rgba(255, 182, 193, 0.8)",
  colorBackground: "#F3E9DC",
  colorText: "#4B2F12",
};

function App() {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Router>
      <ScrollToTop />
        <CustomHeader />
        <div className={classes.mainContainer}>
        <BackgroundMountains />
          <Routes>
            <Route exact path="/" element={<HomePage />}></Route>
            <Route path="/random-story" element={<RandomStoryPage />}></Route>
            <Route path="/contact" element={<ContactPage />}></Route>
            <Route path="/about" element={<AboutUsPage />}></Route>
            <Route path="/app" element={<AppPage />}></Route>
            <Route path="/privacy" element={<PrivacyPolicyPage />}></Route>
            <Route path="/terms" element={<TermsAndConditionsPage />}></Route>
            <Route path="/singlestory" element={<SingleStoryPage />}></Route>
            <Route exact path="/stories/:storyID/" element={<SingleStoryPage />}></Route>
            <Route path="/past-stories" element={<PastStoriesPage />}></Route>
            <Route path="/404" element={<NotFoundPage />}></Route>
            <Route path="*" element={<Navigate to="/404" />}></Route>
          </Routes>
        </div>
        <CustomFooter />
      </Router>
    </ThemeProvider>
  );
}

export default App;
