import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  mainContainer: {

  },
});

function NotFoundPage() {
  const classes = useStyles();
  return(
    <div className={classes.mainContainer}>
      <h1>404</h1>
    </div>
  );
}

export default NotFoundPage;