import React from "react";
import { createUseStyles } from "react-jss";
import mountainsPic from "../pics/mountains.svg"

const useStyles = createUseStyles({
  mainContainer: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    position: "fixed",
    zIndex: "-1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    objectFit: "cover",
    opacity: "25%",
    top: "0",
    // paddingTop: "4em",

  }
});

function BackgroundMountains() {
  const classes = useStyles()
  return(
    <img src={mountainsPic} className={classes.mainContainer} />

  );
}

export default BackgroundMountains;