import React from "react";
import { createUseStyles } from "react-jss";
import loadingPic from "../pics/loading.svg"

const useStyles = createUseStyles({
  mainContainer: {
    width: "100%",

  }
});

function Loading() {
  const classes = useStyles()
  return(
    <img className={classes.mainContainer} src={loadingPic} />
  );
}

export default Loading;