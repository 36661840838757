import React from "react";
import { createUseStyles } from "react-jss";
import PastStoryPreview from "./PastStoryPreview";
import RightSidebar from "./RightSidebar";
import BigPageHeading from "./BigPageHeading";
import { motion } from "framer-motion";

const useStyles = createUseStyles({
  mainContainer: {
    display: "flex",
    width: "100%",
    minHeight: "100vh",
    maxWidth: "1750px",
    justifyContent: "space-between",
    marginTop: "2em",
    "@media(max-width:750px)": {
      flexDirection: "column",
    },
  },
  storiesContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minWidth: "70%",
    // gap: "1em",
  },
  innerStoriesContainer: {
    display: "flex",
    flexDirection: "column-reverse",
  },
});

function PastStoriesPage() {
  const classes = useStyles();
  const [stories, setStories] = React.useState([]);
  React.useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("api/stories");
      const data = await response.json();
      setStories(data.data);
    };
    fetchData();
  }, []);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.storiesContainer}>
        <BigPageHeading heading="Revisit Stories from the Past" />
        <motion.div
          className={classes.innerStoriesContainer}
          initial={{ opacity: 0, translateX: -100 }}
          whileInView={{ opacity: 1, translateX: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          {stories.map((story) => {
            return <PastStoryPreview story={story} />;
          })}
        </motion.div>
      </div>
      <RightSidebar />
    </div>
  );
}

export default PastStoriesPage;
