import React from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: "300px",
    padding: "2em",
    gap: ".25em",
    cursor: "pointer",
    border: `2px solid ${theme.colorPrimary}`,
    // background: theme.colorBackground,
  },
  title: {
    color: theme.colorPrimary,
    fontSize: "1.25rem",
    fontFamily: "Libre Baskerville",
  },
  date: {
    fontFamily: "Libre Baskerville Italic",
    color: theme.colorPrimaryOpacity50,
  },
  tag: {
    fontFamily: "Libre Baskerville Italic",
    color: theme.colorPrimaryOpacity80,
  }
}));

function RelatedStory(props) {
  const classes = useStyles()
  const date = new Date(props.story.publishedAt).toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    year: "numeric",
    day: "numeric",
  });
  return(
    <Link className={classes.mainContainer} to={`/stories/${props.story.id}`}>
        <h3 className={classes.title}>{props.story.title}</h3>
        <h3 className={classes.date}>{date}</h3>
        {/* <h3 className={classes.tag}>mystery</h3> */}
    </Link>

  );
}

export default RelatedStory;