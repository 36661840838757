import React from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "2em 0em",
    width: "100%",
    cursor: "pointer",
    transition: "all .25s ease-in-out",
    borderBottom: `1px solid ${theme.colorPrimaryOpacity25}`,
    "&:hover": {
      padding: "2em 2em",
    },
    "@media(max-width:750px)": {
      flexDirection: "column",
    }
    // background: "red",
  },
  title: {
    fontFamily: "Libre Baskerville",
    fontSize: "2rem",
    color: theme.colorText,
  },
  date: {
    fontFamily: "Libre Baskerville",
    color: theme.colorPrimaryOpacity50,
  }
}));

function PastStoryPreview(props) {
  const classes = useStyles()
  const date = new Date(props.story.attributes.publishedAt).toLocaleDateString(
    "en-US",
    {
      weekday: "long",
      month: "long",
      year: "numeric",
      day: "numeric",
    }
  )
  return(
    <Link to={`/stories/${props.story.id}`}>
      
      <div className={classes.mainContainer}>
        <p className={classes.title}>{props.story.attributes.title}</p>
        <p className={classes.date}>{date}</p>
      </div>
    </Link>

  );
}

export default PastStoryPreview;