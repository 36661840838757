import React from "react";
import { createUseStyles } from "react-jss";
import logo from "../pics/books.svg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    // minHeight: "600px",
    padding: "4em 1em",
    marginTop: "2em",
    background: theme.colorPrimaryOpacity80,
  },
  innerContainer: {
    maxWidth: "1750px",
    width: "100%",
    opacity: "75%",
  },
  separator: {
    height: "5px",
    width: "100%",
    borderTop: `1px solid ${theme.colorTertiary}`,
    borderBottom: `1px solid ${theme.colorTertiary}`,
    opacity: "50%",
  },
  logo: {
    filter: "grayscale(50%)",
    height: "100px",
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2em 0em",
    // cursor: "pointer",
  },
  bottomContainer: {
    display: "flex",
    color: "#f2f2f2",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "2em 0em",
    "@media(max-width: 750px)": {
      flexDirection: "column",
    },
  },
  innerBottomContainer: {
    display: "flex",
    minWidth: "1fr",
    padding: "2em",
    width: "100%",
  },
  link: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width:750px)": {
      gap: ".25em",
      flexDirection: "column",
    },
  },
  bottomRightLink: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    fontSize: "1rem",
    fontStyle: "italic",
    "&:hover $underline": {
      width: "100%",
    },
    // padding: "0em 2em",
  },
  logoTitle: {
    display: "flex",
    textAlign: "center",
    color: theme.colorTertiary,
    fontFamily: "Libre Baskerville",
    fontSize: "4rem",
    fontStyle: "italic",
    textTransform: "uppercase",
    "@media(max-width:750px)": {
      fontSize: "2rem",
    },
  },
  linkContainer: {
    fontFamily: "Libre Baskerville",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    // textAlign: "right",
    color: theme.colorTertiary,
    gap: "1em",
  },
  linkTitle: {
    display: "flex",
    padding: "0em .25em",
  },
  socialMediaContainer: {
    display: "flex",
    gap: "1em",
  },
  socialMediaButton: {
    cursor: "pointer",
    color: theme.colorTertiary,
    fontSize: "2rem",
    transform: "skew(-5deg, -5deg)",
    transition: "all .5s ease-in-out",
    "&:hover": {
      transform: "rotateY(360deg)",
    },
  },
  notificationContainer: {
    fontFamily: "Libre Baskerville",
  },
  notificationTitle: {
    color: theme.colorTertiary,
    fontFamily: "Libre Baskerville Italic",
    fontSize: "2.5rem",
  },
  underline: {
    height: "4px",
    marginTop: ".1em",
    width: "0%",
    backgroundColor: theme.colorTertiaryOpacity50,
    transition: "all .5s ease-in-out",
  },
}));

function CustomFooter() {
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      <div className={classes.innerContainer}>
        <div className={classes.topContainer}>
          <Link className={classes.link} to="/">
            <img className={classes.logo} src={logo} />
            <h3 className={classes.logoTitle}>Whispers of Adventure</h3>
          </Link>
        </div>
        <div className={classes.separator}></div>
        <div className={classes.bottomContainer}>
          <div className={classes.innerBottomContainer}>
            <div className={classes.notificationContainer}>
              <h3 className={classes.notificationTitle}>
                Where stories come to life
              </h3>
            </div>
          </div>
          <div
            className={classes.innerBottomContainer}
            style={{ justifyContent: "center" }}
          >
            <ul className={classes.socialMediaContainer}>
              <FontAwesomeIcon
                className={classes.socialMediaButton}
                icon={faFacebook}
              />
              <FontAwesomeIcon
                className={classes.socialMediaButton}
                icon={faTwitter}
              />
              <FontAwesomeIcon
                className={classes.socialMediaButton}
                icon={faInstagram}
              />
            </ul>
          </div>
          <div
            className={classes.innerBottomContainer}
            style={{ justifyContent: "flex-end" }}
          >
            <div className={classes.linkContainer}>
              <Link className={classes.bottomRightLink} to="/">
                <p className={classes.linkTitle}>Home</p>
                <div className={classes.underline} />
              </Link>
              <Link className={classes.bottomRightLink} to="/random-story">
                <p className={classes.linkTitle}>Random Story</p>
                <div className={classes.underline} />
              </Link>
              <Link className={classes.bottomRightLink} to="/past-stories">
                <p className={classes.linkTitle}>Past Stories</p>
                <div className={classes.underline} />
              </Link>
              <Link className={classes.bottomRightLink} to="/about">
                <p className={classes.linkTitle}>About Us</p>
                <div className={classes.underline} />
              </Link>
              <Link className={classes.bottomRightLink} to="/privacy">
                <p className={classes.linkTitle}>Privacy Policy</p>
                <div className={classes.underline} />
              </Link>
              <Link className={classes.bottomRightLink} to="/terms">
                <p className={classes.linkTitle}>Terms and Conditions</p>
                <div className={classes.underline} />
              </Link>
            </div>
          </div>
        </div>
        <div className={classes.separator}></div>
      </div>
    </div>
  );
}

export default CustomFooter;
