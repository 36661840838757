import React, { Fragment } from "react";
import { createUseStyles } from "react-jss";
import deviceMockup from "../pics/mobilemockup2.png";
import RightSidebar from "./RightSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGooglePlay, faApple } from "@fortawesome/free-brands-svg-icons";
import BackgroundMountains from "./BackgroundMountains";
import PageHeading from "./PageHeading";
import { motion } from "framer-motion";

const useStyles = createUseStyles((theme) => ({
  mainContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    minHeight: "50vh",
    maxWidth: "1750px",
  },
  heroContainer: {
    display: "flex",
    width: "100%",
    "@media(max-width:750px)": {
      flexDirection: "column",
    }
  },
  heroTitle: {
    color: theme.colorPrimary,
    // textTransform: "uppercase",
    fontFamily: "Libre Baskerville Bold",
    fontSize: "3rem",
  },
  heroContent: {
    fontFamily: "Libre Baskerville",
    fontSize: "1.5rem",
    color: theme.colorText,
  },
  leftContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    maxHeight: "700px",
    objectFit: "contain",
    "@media(max-width:750px)": {
      maxHeight: "400px",
    }
  },
  textContainer: {
    width: "100%",
    // maxWidth: "800px",
    display: "flex",
    gap: "1em",
    // alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  appDownloadButton: {
    display: "flex",
    gap: ".5em",
    color: theme.colorTertiary,
    background: theme.colorPrimary,
    padding: "1.25em",
    borderRadius: "1em",
    transition: "all .25s ease-in-out",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.colorPrimaryOpacity80,
    },
    // width: "100%",
  },
  downloadButtonContainer: {
    fontFamily: "Libre Baskerville",
    display: "flex",
    gap: "1em",
    // flexDirection: "column",
    "@media(max-width:750px)": {
      flexDirection: "column",
    }
  },
  downloadButtonTextContainer: {
    display: "flex",
    gap: ".25em",
    flexDirection: "column",
  },
  appButtonText: {
    display: "flex",
    fontSize: "1.75rem",
  },
  appButtonSubText: {
    textTransform: "uppercase",
    fontSize: ".75rem",
    color: theme.colorTertiaryOpacity50,
    display: "flex",
  },
  downloadButtonLogo: {
    fontSize: "2.25rem",
  },
}));

function AppPage() {
  const classes = useStyles();
  return (
    <Fragment>
      {/* <BackgroundMountains /> */}
      <div className={classes.mainContainer}>
        <div className={classes.heroContainer}>
          <div className={classes.leftContainer}>
            <motion.img
              className={classes.image}
              src={deviceMockup}
              initial={{ opacity: 0, translateX: -100 }}
              animate={{ opacity: 1, translateX: 0 }}
              transition={{ duration: 0.5 }}
            />
          </div>
          <div className={classes.textContainer}>
            <PageHeading
              heading="Experience the Adventure on Mobile: iOS & Android App Coming Soon"
              subHeading="Our team is developing a mobile app for iOS and Android users. Expect a user-friendly design with exclusive features, and join our newsletter for progress updates and beta testing access."
              width100
            />
            <motion.div
              className={classes.downloadButtonContainer}
              initial={{ opacity: 0, translateY: -100 }}
              animate={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.5, delay: 0.35 }}
            >
              <div style={{ display: "flex" }}>
                <div className={classes.appDownloadButton}>
                  <FontAwesomeIcon
                    className={classes.downloadButtonLogo}
                    icon={faApple}
                  />
                  <div className={classes.downloadButtonTextContainer}>
                    <p className={classes.appButtonSubText}>Download on the</p>
                    <p className={classes.appButtonText}>App Store</p>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className={classes.appDownloadButton}>
                  <FontAwesomeIcon
                    className={classes.downloadButtonLogo}
                    icon={faGooglePlay}
                  />
                  <div className={classes.downloadButtonTextContainer}>
                    <p className={classes.appButtonSubText}>Get it on</p>
                    <p className={classes.appButtonText}>Google Play</p>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AppPage;
