import React, { Fragment } from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import RightSidebar from "./RightSidebar";
import SingleStory from "./SingleStory";
import loadingPic from "../pics/loading.svg";

const useStyles = createUseStyles({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "100%",
    marginTop: "2em",
    minHeight: "100vh",
    maxWidth: "1750px",
  },
  innerContainer: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    "@media(max-width:750px)": {
      flexDirection: "column",
    },
  },
  loading: {
    width: "100%",
    minWidth: "70%",
  },
});

function HomePage() {
  const classes = useStyles();
  const [story, setStory] = React.useState([]);
  React.useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/api/stories/latest-story");
      const data = await response.json();
      setStory(data[0]);
    };
    fetchData();
  }, []);
  return (
    <div className={classes.mainContainer}>
      <div className={classes.innerContainer}>
        {story ? (
          <SingleStory
            story={story}
          />
        ) : (
          <img className={classes.loading} src={loadingPic} />
        )}
        <RightSidebar />
      </div>
    </div>
  );
}

export default HomePage;
