import React from "react";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import loadingPic from "../pics/loading.svg";

import SingleStory from "./SingleStory";
import RightSidebar from "./RightSidebar";

const useStyles = createUseStyles({
  mainContainer: {
    display: "flex",
    // flexDirection: "column",
    minHeight: "100vh",
    // alignItems: "center",
    margin: "auto",
    width: "100%",
    marginTop: "2em",
    maxWidth: "1750px",
    // padding: "0em 1em"
  },
  innerContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    "@media(max-width:750px)": {
      flexDirection: "column",
    },
  },
});

function SingleStoryPage() {
  const classes = useStyles();

  const { storyID } = useParams();

  const [story, setStory] = React.useState([]);
  React.useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`/api/stories/${storyID}`);
      const data = await response.json();
      setStory(data.data.attributes);
    };
    fetchData();
  }, [storyID]);
  return (
    <div className={classes.mainContainer}>
      <div className={classes.innerContainer}>
        {story ? (
          <SingleStory
            title={story.title}
            body={story.body}
            date={story.date_active}
            story={story}
          />
        ) : (
          <img className={classes.loading} src={loadingPic} />
        )}

        <RightSidebar />
      </div>
    </div>
  );
}

export default SingleStoryPage;
