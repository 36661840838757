import React from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import bookIcon from "../pics/book-icon2.png";
import bookIconRandom from "../pics/book-icon-random.png";
import bookIconStack from "../pics/book-icon-stack.png";

const useStyles = createUseStyles((theme) => ({
  // outerContainer: {
  //   width: "100%",
  //   display: "flex",
  //   alignItems: "flex-end",
  // },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    color: theme.colorText,
    position: "sticky",
    alignSelf: "flex-start",
    // position: "-webkit-sticky",
    top: "1em",
    width: "100%",
    // right: "0",
    // minWidth: "30%",
    marginLeft: "2em",
    "@media(max-width:750px)": {
      margin: "0",
      position: "static",
      // padding: "1em"
    },
  },
  storyList: {
    display: "flex",
    flexDirection: "column",
    borderBottom: `1px solid ${theme.colorPrimary}`,
    fontFamily: "Libre Baskerville",
    // borderTop: "1px solid gray",
    paddingBottom: "1em",
    paddingTop: "1em",
  },
  sectionTitle: {
    color: theme.colorPrimary,
    fontFamily: "Libre Baskerville",
    fontSize: "1.5rem",
    marginBottom: ".25em",
  },
  storyContainer: {
    display: "flex",
    alignItems: "center",
    padding: "1em 0em",
    cursor: "pointer",
    fontStyle: "italic",
    transition: "all .25s ease-in-out",
    "&:hover": {
      backgroundColor: theme.colorPrimaryOpacity25,
      padding: "1em 1em",
    },
  },
  icon: {
    height: "20px",
    marginRight: ".5em",
  },
}));

function RightSidebar() {
  const classes = useStyles();
  const [stories, setStories] = React.useState([]);
  React.useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/api/stories/latest10-stories");
      const data = await response.json();
      setStories(data);
    };
    fetchData();
  }, []);

  return (
    <motion.div
      className={classes.mainContainer}
      initial={{ opacity: 0, translateY: 100 }}
      whileInView={{ opacity: 1, translateY: 0 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
    >
      <ul className={classes.storyList}>
        <h3 className={classes.sectionTitle}>Stories You May Have Missed</h3>
        {stories?.map((story) => {
          return (
            <Link to={`/stories/${story.id}`}>
              <li className={classes.storyContainer}>
                <img src={bookIcon} className={classes.icon} />
                {story.title}
              </li>
            </Link>
          );
        })}
        <Link to="/past-stories">
          <li className={classes.storyContainer}>More Stories ...</li>
        </Link>
      </ul>
      <ul className={classes.storyList}>
        <h3 className={classes.sectionTitle}>Hidden Story Treasures</h3>
        <Link to="/random-story">
          <li className={classes.storyContainer}>
            <img src={bookIconRandom} className={classes.icon} />
            Random Story
          </li>
        </Link>
        <Link to="/past-stories">
          <li className={classes.storyContainer}>
            <img src={bookIconStack} className={classes.icon} />
            Past Stories
          </li>
        </Link>
        {/* <li className={classes.storyContainer}>Dark Mode</li> */}
      </ul>
      <ul className={classes.storyList}>
        <h3 className={classes.sectionTitle}>Inside the Command Center</h3>
        <Link to="/about">
          <li className={classes.storyContainer}>About Us</li>
        </Link>
        <Link to="/app">
          <li className={classes.storyContainer}>Get the App</li>
        </Link>
        <Link to="/privacy">
          <li className={classes.storyContainer}>Privacy Policy</li>
        </Link>
        <Link to="/terms">
          <li className={classes.storyContainer}>Terms and Conditions</li>
        </Link>
      </ul>
    </motion.div>
  );
}

export default RightSidebar;
