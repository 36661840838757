import React from "react";
import { createUseStyles } from "react-jss";
import { motion } from "framer-motion";
import Button from "./Button";
import { Link } from "react-router-dom";

const useStyles = createUseStyles((theme) => ({
  mainContainer: (props) => ({
    display: "flex",
    flexDirection: props.reverse ? "row-reverse" : "row",
    justifyContent: "space-between",
    alignItems: props.centered ? "center" : "flex-start",
    width: "100%",
    maxWidth: "1750px",
    // padding: "0rem 10rem",
    "@media(max-width: 750px)": {
      flexDirection: "column",
    }
  }),
  heading: {
    fontFamily: "Libre Baskerville",
    color: theme.colorPrimary,
    fontSize: "3rem",
  },
  subHeading: {
    fontFamily: "Libre Baskerville",
    color: theme.colorText,
    fontWeight: "500",
    fontSize: "2rem",
    marginBottom: ".5em",
  },
  leftContainer: (props) => ({
    display: "flex",
    flexDirection: "column",
    gap: "1em",
    width: props.width100 ? "100%" : "50%",
    "@media(max-width:750px)": {
      width: "100%"
    }
  }),
  image: (props) => ({
    display: "block",
    height: props.height ? `${props.height}px` : "100%",
    maxHeight: props.maxHeight ? "" : "350px",
    "@media(max-width:750px)": {
      height: "350px",
    }
  }),
  outerImageContainer: {
    display: "flex",
    alignItems: "center",   
    justifyContent: "center",
    "@media(max-width:750px)": {
      // position: "absolute"
    }
  },
  link: {
    display: "flex",
  }
}));

function PageHeading(props) {
  const classes = useStyles(props);
  return (
    <div className={classes.mainContainer}>
      <motion.div
        className={classes.leftContainer}
        initial={{ opacity: 0, translateY: -100 }}
        whileInView={{ opacity: 1, translateY: 0 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
      >
        <h2 className={classes.heading}>{props.heading}</h2>
        <h3 className={classes.subHeading}>{props.subHeading}</h3>
        {props.callToAction ? (
          <div style={{display: "flex"}}>
          <Link to={props.link ? `/${props.link}` : "/contact"}>
            <Button
              text={props.buttonText ? props.buttonText : "Get A Free Quote"}
              color
            />
          </Link>
          </div>
        ) : (
          ""
        )}
      </motion.div>
      {props.width100 || props.noImage ? (
        ""
      ) : (
        <div className={classes.outerImageContainer}>
          <motion.img
            className={classes.image}
            src={props.image}
            initial={{ opacity: 0, translateY: 100 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.25 }}
          />
        </div>
      )}
    </div>
  );
}

export default PageHeading;
